/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-notifier/styles.css";

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-pane .leaflet-marker-icon,
.leaflet-pane .leaflet-marker-shadow,
.leaflet-pane .leaflet-tile,
.leaflet-pane .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

/* Fix for leaflet's default icon path */
.leaflet-container .leaflet-control-draw .leaflet-draw-toolbar a {
  background-image: url('/assets/leaflet-draw-spritesheet.png');
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; overflow: hidden;}


.span0 {
  text-indent: 50px;
  letter-spacing: 3px;
  font-size: xx-large;
  color: #1c8108
}


.span1{
  color: gray; 
  font-size: xx-large;
}

.span-small{
  color: black; 
  font-size: large;
}

div{
  cursor: pointer;
}

.btn2{
  padding: 10px; 
  margin: 3%; 
  height: 10%; 
  letter-spacing: 3px;
  border: 3px solid grey; 
border-radius: 15px;
background-color: white;

}

.btn2:hover{
  background-color:#10684E;


}

.btn2:focus{
  background-color:  #E3E3E3;
}

.marker-cluster-small {
 /* background-color: #49afa5 !important;
  background: #2d84c8;
        border-radius: 50%;
        text-align: center;
        color: white;
        font-weight: 700;
        border: 1px solid white;
        font-family: monospace;
        display: flex;
        justify-content: center;*/
}

.marker-cluster-small div {
 /* background-color: #1c9489 !important;
  color: #fff !important;

  text-align: center;*/

}

dialog{
  position: static;
}
